@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;700;900&family=Open+Sans:wght@300;400;600;700&display=swap');
/*
font-family: 'Lato', sans-serif;
font-family: 'Open Sans', sans-serif;
*/

:root {
    --ferraro-color-main: #e4992f; /* Use it with var(--ferraro-color-main); */
    --ferraro-color-main-dark: #c98934; /* Use it with var(--ferraro-color-main-dark); */
    --ferraro-color-points: #fcae5a; /* Use it with var(--ferraro-color-main); */
}

*
{
    margin: 0;
    padding: 0;
}

html,
body
{
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.hidden {
    visibility: hidden !important;
    opacity: 0 !important;
    pointer-events: none !important;
}

#rooms-selector-container {
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: #03123b00;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: visibility 0.3s, opacity 0.3s;
}

#rooms-selector-panel {
    width: 100vw;
    max-height: 70vh;
    padding: 30px 0;
    background-color: #22222290;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: normal;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
    overflow-y: scroll;
}

.rooms-selector-room {
    margin: 10px 10px 55px 10px;
    background-color: #ffffff;
    height: 20vh;
    width: auto;
    aspect-ratio: 16/9;
    cursor: pointer;
}

.rooms-selector-room:hover .rooms-selector-title {
    background-color: #000000cc;
}

#room-background-panel {
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: #30303090;
    display: flex;
    justify-content: center;
    align-items: center;
    /*visibility: hidden; /* Remove when completed */
    transition: visibility 0.3s, opacity 0.3s;
}

#room-panel {
    position: relative;
    /* width: 80vw; */
    height: 60vh;
    display: flex;
    justify-content: center;
    align-items: center;
    /*visibility: hidden; /* Remove when completed */
    /* border: 2px dashed #50aaff20; */
}

.rooms-selector-room-img {
    width: 100%;
    height: auto;
}

.rooms-selector-title {
    color: #ffffff;
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-size: 1.3em;
    text-align: center;
    background-color: #00000044;
    padding: 12px 0;
    transition: background-color 0.2s;
}

#room-machine-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    user-select: none;
    pointer-events: none;
}

/* ---------- */

/* Scrollbar */
*::-webkit-scrollbar {
    width: 7px;
}
* {
    scrollbar-width: thin;
    scrollbar-color: #ffffff90;
}
*::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0);
}
*::-webkit-scrollbar-thumb {
    background-color: #ffffff90;
    border-radius: 0px;
}
/* Scrollbar */

/* Underline */
.underline {
    text-decoration: none;
    position: relative;
}

.underline:after {
    position: absolute;
    content: '';
    height: 2px;
    /* Adjust this to move up and down. You may have to adjust the line height of the paragraph if you move it down a lot. */
    bottom: -7px;

    /****** Optional values below ******/

    /* Center - (optional) use with adjusting width   */
    margin: 0 auto;
    left: 0;
    right: 0;
    width: 80%;
    background: #ffffff;

    /* Optional animation */
    -o-transition: 0.5s;
    -ms-transition: 0.5s;
    -moz-transition: 0.5s;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

/* Optional hover classes used with animation */
.underline:hover:after {
    width: 100%;
    background: var(--ferraro-color-main) !important;
}
/* Underline */

.three-background
{
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    user-select: none;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    transition: visibility 0.2s, opacity 0.2s ease-in-out;
}

#intro-background
{
    background-image: url("../static/images/background/bg_intro.jpg");
}


#room-selector-background {
    width: 100%;
    height: 100%;
}

#room-selector-background::before,
#room-selector-background::after
{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    background-size: cover;
    /* background-image: url("../static/images/background/bg_selector_01.jpg"); */
    transition: all 0.25s linear;
    z-index: -1; /* below intro-background */
}

#room-selector-background::before
{
    background-image: url("../static/images/background/bg_selector_01.jpg");
    opacity: 1;
}

#room-selector-background::after
{
    background-image: url("../static/images/background/bg_selector_02.jpg");
    opacity: 0;
}

.room-selector-background-inverted::before
{
    opacity: 0 !important;
}

.room-selector-background-inverted::after
{
    opacity: 1 !important;
}



/*
#room-selector-background {
    width: 100%;
    height: 100%;
}

#room-selector-background::before,
#room-selector-background::after {
    width: 100%;
    height: 100%;
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    transition: all 1s;
    background-size: cover;
}

#room-selector-background::before {
    background-image: url(https://picsum.photos/id/14/1600/900);
    opacity: 0;
}

#room-selector-background::after {
    background-image: url(https://picsum.photos/id/13/1600/900);
    opacity: 1;
}

.room-selector-background-inverted::before {
    opacity: 1 !important;
}

.room-selector-background-inverted::after {
    opacity: 0 !important;
}
*/




#room-selector-background2
{
    background-image: url("../static/images/background/bg_selector_02.jpg");
    z-index: -1; /* below intro-background */
}

#room-background
{
    background-image: url("../static/images/background/bg_group_elements.jpg");
    z-index: -1; /* below intro-background */
}

#model-background
{
    background-image: url("../static/images/background/bg_models_v2.jpg");
    z-index: -1; /* below intro-background */
}

#intro-video-container
{
    z-index: 1000;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #0000005c;
    opacity: 1;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    transition: all 0.4s ease-in-out;
}

#intro-video
{
    max-width: 70vw;
    height: 70vh;
    aspect-ratio: 16/9;
}

#intro-video-close-button
{
    position: absolute;
    content: url('../static/images/ui/close_icon.png');
    bottom: 40px;
}

.webgl
{
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
    /* z-index: -100; */
    user-select: none;
}

#input-detector {
    position: absolute;
    width: 100%;
    height: 100%;
}

.black-background-fullscreen-centered {
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: #000000;
    /* border: 4px dashed #ffffff50; */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100000;
    transition: visibility 0.1s, opacity 0.1s ease-in-out;
}

#rotate-device-container {
    transition: visibility 0, opacity 0;
}

#rotate-device-image {
    content: url('../static/images/ui/rotate.png');
}

#loading-container {
}

#loading-container.hidden {
    transition: visibility 0.5s 0.3s, opacity 0.5s 0.3s ease-in-out;
}

.loading-bar
{
    position: absolute;
    top: 50%;
    width: 100%;
    height: 16px;
    background: var(--ferraro-color-main);
    transform: scaleX(0);
    transform-origin: top left;
    transition: transform 0.5s;
}

.loading-bar.ended
{
    /* transform: scaleX(0);
    transform-origin: 100% 0; */
    transform: scaleX(1);
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
}

#loading-text {
    position: absolute;
    top: 43vh;
    width: 100%;
    color: #ffffff;
    font-size: 2em;
    text-align: center;
    margin: auto;
}

.loading-image {
    content: url('../static/images/ui/load_inner.png');
}

.loading-spinner {
    content: url('../static/images/ui/ring.svg');
    position: absolute;
}

.logo
{
    position: absolute;
    width: fit-content;
    display: flex;
    justify-content: center;
    top: 27px;
    left: 40%;
    right: 40%;
    margin: 0 auto;
    user-select: none;
    z-index: 900000;
}

#logo-img
{
    max-width: 30vmin;
    user-select: none;
    pointer-events: none;
}

#popup-message-container {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    z-index: 1000;
    user-select: none;
    transition: visibility 0.5s, opacity 0.5s;
}

#popup-message {
    position: relative;
    background-color: #000000dd;
    padding: 40px 40px;
    width: 41vw;
    max-height: 90vh;
    color: #ffffff;
    font-family: 'Lato', sans-serif;
    text-align: center;
}

#popup-message-close-button {
    content: url('../static/images/ui/close_icon.png');
    position: absolute;
    right: -12px;
    top: -12px;
}


#fullscreen-gallery-container, #fullscreen-video-container {
    position: absolute;
    background-color: #000000cc;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    z-index: 5001;
    user-select: none;
    transition: visibility 0.5s, opacity 0.5s;
}

#fullscreen-video-container {
    z-index: 6001;
}

.fullscreen-gallery-parent, .fullscreen-video-parent {
    position: relative;
    background-color: #000000dd;
    /* padding: 40px 40px; */
    /* width: 41vw; */
    height: 70vh;
    color: #ffffff;
    text-align: center;
    box-shadow: 0px 1px 40px #000000;
}

.fullscreen-gallery-parent {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

#fullscreen-gallery-close-button, #fullscreen-video-close-button {
    content: url('../static/images/ui/close_icon.png');
    position: absolute;
}

#fullscreen-gallery-close-button {
    bottom: 20px;
    z-index: 10;
}

#fullscreen-video-close-button {
    right: -12px;
    top: -12px;
}

#fullscreen-gallery-img, #fullscreen-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
}

#fullscreen-gallery-container .swiper {
    width: 100%;
    /* height: 100%; */
    user-select: none;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#fullscreen-gallery-container .swiper-wrapper {
    height: 69vh !important;
    margin-top: 25px !important;
    margin-bottom: 10px !important;
}

#fullscreen-gallery-container .swiper-slide img {
    max-width: 100%;
    height: 100%;
    object-fit: contain;
    opacity: 0.35;
    transform: scale(0.8);
    transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
}

#fullscreen-gallery-container .swiper-slide-active img {
    transform: scale(1);
    opacity: 1;
    /* border: 2px solid #c07e1f30; */
}

#fullscreen-gallery-container .swiper-slide-active {
    z-index: 1;
    /* border: 2px solid #ff000030; */
}


.round-color-button {
    background-color: var(--ferraro-color-main);
    width: 50px;
    border-radius: 200px;
    cursor: pointer;
    transition: background-color 0.3s, border 0.3s;
}

.round-color-button:hover {
    background-color: var(--ferraro-color-main-dark);
}

#popup-message-title {
    font-size: 1.7em;
    font-weight: 800;
    margin-bottom: 30px;
    line-height: 1.1em;
}

#popup-message-content {
    font-size: 1.3em;
    line-height: 1.2em;
}

.point
{
    position: absolute;
    top: 50%;
    left: 50%;
    user-select: none;
    z-index: 0;
}

.point.visible:hover {
    z-index: 3000 !important;
}

.point .point-circle
{
    position: absolute;
    width: 7vmin;
    height: 7vmin;
    top: -3.5vmin; /* width / 2 */
    left: -3.5vmin; /* height / 2 */
    border-radius: 50%;
    background-color: #ffffff41; /* rgba(50, 187, 192, 0.4) */
    box-shadow: 0px 0px 40px #00000055;
    color: #414141;
    border: 2px solid #ffffff;
    display: flex;
    align-items: center;
    align-content: center;
    justify-items: center;
    justify-content: center;
    cursor: help;
    transform: scale(0, 0);
    opacity: 0;
    transition: all 0.3s;
    z-index: 1;
}

.point.visible .point-circle
{
    transform: scale(1, 1);
    opacity: 0.85;
}

#room-background-panel .point.visible .point-circle
{
    opacity: 1;
}

.point.visible:hover .point-circle
{
    transform: scale(1.15, 1.15);
    opacity: 1;
}

.point.visible:hover .point-circle-inner
{
    transform: scale(1.2, 1.2);
}

.point-circle-inner
{
    width: 3.5vmin;
    height: 3.5vmin;
    top: -1.75vmin;
    left: -1.75vmin;
    border-radius: 50%;
    background-color: #ffffff;
    box-shadow: 0px 0px 40px #00000055;
    font-family: 'Lato', sans-serif;
    font-weight: 900;
    font-size: 2.6vmin;
    /* text-shadow: 2px 2px 1px #00000022; */
    text-align: center;
    display: flex;
    align-items: center;
    align-content: center;
    justify-items: center;
    justify-content: center;
    transition: all 0.3s;
}

.point .point-text-box
{
    /* display: none; */
    opacity: 0;
    position: absolute;
    /* width: 170px; */
    width: 39vmin;
    /* height: 34px; */
    height: 4.7vmin;
    /* top: -25px; */
    transform: translateY(-2.35vmin);
    display: flex;
    align-items: center;
    align-content: center;
    justify-items: center;
    justify-content: center;
    /* left: -60px; /* Add 10px to adjust for padding: 10px*/
    background-color: #000000b9; /* #2e2e2edc */
    /* padding: 10px; */
    /* padding-top: 2px; */
    /* border-radius: 2px; */
    transition: opacity 0.3s;
    pointer-events: none;
}

.point:hover .point-text-box
{
    opacity: 1;
}

.point .point-text
{
    /* position: absolute; */
    /* left: 80px; */
    width: 86%;
    transform: translateX(1.7vmin);
    color: #ffffff;
    /* background-color: rgba(255, 0, 0, 0.25); */
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    font-size: 2.0vmin;
    line-height: 1.0em;
    text-align: center;
    text-transform: uppercase;
    pointer-events: none;
}

.point-main-color .point-circle
{
    border: 3px solid var(--ferraro-color-points) !important;
    background-color: #fcae5a41;
}

.point-main-color .point-circle-inner
{
    background-color: var(--ferraro-color-points) !important;
}

.centered-lower-text-container
{
    position: absolute;
    bottom: 4%;
    transform: translateY(-101px);
    width: 100%;
    z-index: 10;
    display: flex;
    justify-content: center;
    justify-items: center;
    align-content: center;
    align-items: center;
    opacity: 1;
    transition: all 0.3s;
}

#room-name-container {
    transform: translateY(-50px);
}

.centered-lower-text
{
    position: absolute;
    width: 100%;
    text-align: center;
    color: #ffffff;
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    font-size: 2rem;
    text-transform: uppercase;
    pointer-events: none;
    user-select: none;
}

.centered-lower-text-line
{
    position: absolute;
    /* left: 40%; */
    width: 270px;
    bottom: -25px;
    height: 2px;
    background-color: #ffffff67;
    pointer-events: none;
}

#room-name-subtitle {
    font-size: 1.1rem;
    font-weight: 200;
    line-height: 1.2rem;
    top: 34px;
    text-transform: none;
    width: 37%;
    /* background-color: #00000033; */
}

.product-description-container
{
    position: absolute;
    width: 50%;
    height: 100%;
    top: 0%;
    background-color: #343434;
    box-shadow: 0px 0px 40px #000000bb;
    /* margin-right: 60px; */
    z-index: 5000;
    pointer-events: none;
    user-select: none;
    opacity: 0;
    transition: all 0.3s;

    /* Prevent padding from making the size bigger: */
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;         /* Opera/IE 8+ */
    /* padding: 0 90px; */
    padding: 0 7%;
}

.product-description-container.visible
{
    opacity: 1;
    pointer-events: initial;
    user-select:initial
}

.product-description-content {
    background-color: #ffff0099;
}

.product-description-wrapper
{
    width: 100%;
    height: 60%;
    top: 4%;
    position: relative;
}

.product-description
{
    position: relative;
    width: 100%;
    height: 100%;
    /* margin-right: 60px; */
        /* top: 20px; */
        /* left: 20px; */
    /* opacity: 0; */
    /* background-color: #00000020; */
    /* padding: 0 6px; */
    padding: 0 15px 0 6px;
    border-radius: 2px;
    color: #ffffff;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 1.15em;
    line-height: 1.4em;
    white-space: pre-line;
    transition: all 0.3s;
    z-index: 10000;
    /* pointer-events: none; */
    /* user-select: none; */
    overflow-x: hidden;
    overflow-y: auto;
}

#descriptionText
{
    margin-bottom: 45px;
}

#nameText
{
    color: #ffffff;
    font-size: 4.5vmin;
    font-weight: 600;
    line-height: 1.1em;
    text-align: center;
    text-transform: uppercase;
    /* margin-bottom: 40px; */
    padding-top: 13.2vh;
    height: 4.4em;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btnColor
{
    width: 50px;
    height: 50px;
    color: #ffffff;
    font-family: 'Lato', sans-serif;
    text-align: center;
    font-weight: 900;
    background-color: #ffffff38;
    border-radius: 50px;
    border: 2px solid #ffffff;
    display: flex;
    justify-content: center;
    justify-items: center;
    align-content: center;
    align-items: center;
    user-select: none;
    transition: all 0.3s;
}

.btnColor:hover
{
    background-color: #ffffff66;
    color: #000000;
}

.btnColor:hover:active
{
    background-color: #a0a0a0;
    color: #000000;
    transition: background-color 0.1s;
}

.btnDark
{
    /* width: 65px; */
    /* height: 65px; */
    width: 8vh;
    height: 8vh;
    /* box-sizing: content-box; */
    color: #ffffff;
    text-align: center;
    font-family: 'Lato', sans-serif;
    font-size: 1.7em;
    font-weight: 900;
    text-decoration: none;
    background-color: #1e1e1e99;
    border: 2px solid #00000000;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    justify-items: center;
    align-content: center;
    align-items: center;
    user-select: none;
    cursor: pointer;
    transition: background-color 0.3s, border 0.3s, visibility 0.3s, opacity 0.3s;
}

.btnDark:hover
{
    background-color: #00000099;
    border: 2px solid #ffffff;
    /* color: #000000; */
}

#descriptionClose
{
    position: absolute;
    /* right: 25px;
    top: 10px; */
    bottom: 50px;
    padding-right: 34px;
    margin: auto;
    /* background-color: #00000055; */
    width: 73%;
    text-align: center;
    color: #ffffff;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    line-height: 1.5em;
    cursor: pointer;
    user-select: none;
}

#descriptionClose-text
{
    display: inline-block;
    padding: 0 20px;
    font-size: 1.5em;
}

#descriptionPrevious
{
    content: url('../static/images/ui/arrow_icon_left.png');
    position: absolute;
    left: -60px;
    top: 35%;
}

#descriptionNext
{
    content: url('../static/images/ui/arrow_icon_right.png');
    position: absolute;
    right: -60px;
    top: 35%;
}

.description-video
{
    width: 100%;
    height: auto;
    aspect-ratio: 16/9;
    /* margin: 20px 0; */
    margin: 0;
}

/* Youtube player */
.ytp-mute-button {
    display: none !important;
}

.ytp-settings-button {
    display: none !important;
}

/* GALLERY */
#description-galleryDISABLED
{
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    /* min-height: 330px; */
    margin: 30px 0;
}

#description-gallery {
    display: grid;
    /* grid-template-rows: 1fr 1fr 1fr; */
    margin-top: 70px;
    margin-bottom: 60px;
    /* grid-template-columns: minmax(0, 100%) minmax(0, 100%) minmax(0, 100%); */
    /* grid-template-areas:
    "description-gallery-previous-image . ."
    ". description-gallery-current-image ."
    ". . description-gallery-next-image"; */
    /* grid-template-areas: "description-gallery-previous-image description-gallery-current-image description-gallery-next-image"; */
    align-items: center;
    justify-content: center;
    justify-items: center;
}

.description-gallery-alone {
    display: flex !important;
    margin-top: 40px !important;
    margin-bottom: 30px !important;
}

#description-galleryDISABLED img
{
    /* width: 70%; */
    width: fit-content !important;
    min-width: 50%;
    /* height: 32vh; */
    /* height: auto; */
    max-height: 30vh;
    /* aspect-ratio: 16/9; */
    object-fit: cover;
    object-fit: contain;
    background-color: #ffffff;
    display: block;
    /* margin-top: 50px; */
    cursor: pointer;
    transition: all 0.2s ease;
    /* transition: opacity 0.2s ease, width 0.2s ease, box-shadow 0.2s ease; */
}

#description-gallery-previous-imageDISABLED
{
    position: absolute;
    transform: translate(-22%, 0) scale(0.95);
    -webkit-transform: translate(-22%, 0) scale(0.95);
    opacity: 0.3;
}

#DISABLEDdescription-gallery-previous-image:hover
{
    /* transform: translate(-22%, 0) scale(1); */
    /* -webkit-transform: translate(-22%, 0) scale(1); */
    transform: translate(40%, -20%) scale(1);
    opacity: 0.99;
}

/*#description-gallery-next-image:hover ~ #description-gallery-current-image
{
    opacity: 0.8;
}

#description-gallery-previous-image:hover ~ #description-gallery-current-image
{
    opacity: 0.8;
}*/

#description-gallery-current-imageDISABLED
{
    position: absolute;
    opacity: 1;
    z-index: 3;
    box-shadow: 0 0 30px #000000aa;
    transform: translate(0, 30%) scale(1);
    -webkit-transform: translate(0, 30%) scale(1);
}

#description-gallery-current-image:hover
{
    box-shadow: 0 0 40px #000000aa;
    /* transform: translate(0, 30%) scale(1.1); */
    /* -webkit-transform: translate(0, 30%) scale(1.1); */
    transform: scale(1.06);
}

.description-gallery-current-image-alone
{
    position: relative !important;
    max-width: 75% !important;
    max-height: 320px !important;
    transform: translate(0, 0) scale(1) !important;
    -webkit-transform: translate(0, 0) scale(1) !important;
}

.description-gallery-current-image-alone:hover
{
    transform: translate(0, 0) scale(1.05) !important;
    -webkit-transform: translate(0, 0) scale(1.05) !important;
}

#description-gallery-next-imageDISABLED
{
    position: absolute;
    transform: translate(22%, 60%) scale(0.95);
    -webkit-transform: translate(22%, 60%) scale(0.95);
    opacity: 0.3;
}

#DISABLEDdescription-gallery-next-image:hover
{
    /* transform: translate(22%, 60%) scale(1); */
    /* -webkit-transform: translate(22%, 60%) scale(1); */
    transform: translate(-40%, 20%) scale(1);
    opacity: 0.99;
}

#description-gallery-previous-image {
    max-width: 100%;
    max-height: 250px;
    grid-area: description-gallery-previous-image;
    transform: translate(-40px, -20px) scale(0.95);
    opacity: 0.4;
    cursor: pointer;
    transition: all 0.2s ease;
}
#description-gallery-current-image {
    max-width: 100%;
    max-height: 250px;
    grid-area: description-gallery-current-image;
    cursor: pointer;
    box-shadow: 0 0 45px #000000ff;
    z-index: 3;
    transition: all 0.2s ease;
}
#description-gallery-next-image {
    max-width: 100%;
    max-height: 250px;
    grid-area: description-gallery-next-image;
    transform: translate(40px, 20px) scale(0.95);
    opacity: 0.4;
    cursor: pointer;
    transition: all 0.2s ease;
}

.swiper-button-prev-gallery {
    content: url('../static/images/ui/arrow_icon_left.png');
    position: absolute;
    left: 70px;
    top: 47%;
    z-index: 100;
}
.swiper-button-next-gallery {
    content: url('../static/images/ui/arrow_icon_right.png');
    position: absolute;
    right: 70px;
    top: 47%;
    z-index: 100;
}
.mySwiper-gallery .swiper-button-disabled {
    opacity: 0.2;
}
/* END GALLERY */

.hide-completely {
    opacity: 0 !important;
    display: none !important;
    transform: scale(0) !important;
    -webkit-transform: scale(0) !important;
}

.display-none
{
    display: none !important;
}

#description-buttons
{
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    display: flex;
    justify-content: space-evenly;
    margin: 20px 0 20px 0;
}

#description-video-buttons
{
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    display: flex;
    justify-content: center;
    margin: 10px 0 10px 0;
}

.color-button
{
    font-family: 'Open Sans', sans-serif;
    font-size: 0.95em;
    font-weight: 700;
    text-align: center;
    background-color: var(--ferraro-color-main);
    color: #ffffff;
    padding: 10px 24px;
    margin-right: 10px;
    top: 10px;
    text-decoration: none;
    text-transform: uppercase;
    user-select: none;
    transition: all 0.3s;
}

.color-button:hover{
    background-color: var(--ferraro-color-main-dark);
}

#contact-us-button
{
    position: absolute;
    bottom: 35px;
    right: 35px;
    z-index: 6001;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #ffffff;
    font-size: 1.2em;
    font-weight: 300;
}

#contact-us-button-text {
    margin-right: 10px;
    user-select: none;
    font-weight: 700;
    text-transform: uppercase;
}

/* #contact-us-button-text:after {
    background: #ffffff67;
} */

#contact-us-button-image {
    content: url('../static/images/ui/handshake_icon.png');
}

#back-to-machines
{
    position: absolute;
    bottom: 35px;
    left: 35px;
    z-index: 6001;
    content: url('../static/images/ui/home_icon.png');
}

#menu-button
{
    position: absolute;
    top: 35px;
    right: 35px;
    z-index: 6001;
    content: url('../static/images/ui/settings button.png');
}

/* MENU */
#menu-background{
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: #50505090;
    z-index: 5900;
    opacity: 0;
    pointer-events: none;
    transition: visibility 0.3s, opacity 0.3s;
}

#menu-background.visible
{
    opacity: 1;
    pointer-events: initial;
}

#menu-panel
{
    position: absolute;
    margin: 0 auto;
    left: 0;
    right: 0;
    box-sizing: border-box;
    background-color: #000000bd;
    max-width: 520px;
    height: 100%;
    z-index: 6000;
    border-bottom: 12px solid var(--ferraro-color-main);
    /* overflow-y: scroll; */
}

#menu-socials
{
    width: 100%;
    /* background-color: #72570b55; */
    display: flex;
    justify-content: center;
    justify-items: center;
    margin-top: 15vh;
}

.social-button
{
    width: 50px;
    height: 50px;
    border-radius: 100%;
    border: 2px solid #ffffff;
    color: #ffffff;
    font-weight: 600;
    display: flex;
    justify-content: center;
    justify-items: center;
    align-content: center;
    align-items: center;
    user-select: none;
    transition: all 0.2s;
    margin: 0 9px;
}

.social-button:hover
{
    background-color: #ffffff40;
}

.social-button-image {
    width: 60%;
}

#social-button-youtube {
    content: url('../static/images/ui/youtube.png');
}

#social-button-linkedin {
    content: url('../static/images/ui/linkedin.png');
}

#social-button-facebook {
    content: url('../static/images/ui/facebook.png');
}

#menu-buttons
{
    /* margin: 10px 0; */
    /* background-color: rgba(255, 196, 0, 0.034); */
    overflow-y: scroll;
    position: absolute;
    width: 100%;
    bottom: 80px;
    top: 23vh;
}

.menu-btn
{
    display: block;
    margin: 20px auto;
    padding: 15px 0;
    font-size: 1.1em;
    max-width: 50%;
    cursor: pointer;
}

#menu-video-buttons-parent {
    margin-top: 40px;
}
#menu-link-buttons-parent {
    /* margin-top: 35px; */
}

.menu-video-btn, .menu-link-btn, .description-link-btn {
    max-width: 40%;
    height: 45px;
    padding: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.description-link-btn {
    max-width: 50%;
    min-width: 35%;
    margin: 0 8px;
    text-transform: none;
}

.menu-video-btn-image, .menu-link-btn-image, .description-link-btn-image, .description-video-btn-image {
    height: 100%;
    border-right: 1px solid #c07e1f;
}

.menu-video-btn-image {
    content: url('../static/images/ui/video_file_icon.png');
}

.menu-video-btn-text, .menu-link-btn-text, .description-link-btn-text {
    width: 78%;
    font-weight: 600;
}

.menu-link-btn-image {
    /* content: url('../static/images/ui/pdf_icon.png'); */
}

.description-video-btn-image {
    content: url('../static/images/ui/video_icon.png');
}

.white-text-button
{
    color: #ffffff;
    margin: 0 auto;
    width: max-content;
    text-align: center;
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    font-size: 20px;
    user-select: none;
    cursor: pointer;
}

#menu-close-button
{
    position: absolute;
    bottom: 50px;
    left: 50px;
    right: 50px;
}
/* END MENU */

#language-menu
{
    position: absolute;
    z-index: 9000;
    left: 40px;
    top: 40px;
    display: inline-flex;
    font-family: 'Lato', sans-serif;
    font-size: 22px;
    color: #ffffff;
    user-select: none;
}

.language-buttom
{
    font-weight: 300;
    margin: 0 7px;
    cursor: pointer;
    opacity: 0.5;
    transition: opacity 0.3s;
}

.language-buttom.selected
{
    font-weight: 700;
    opacity: 1;
}

#debugMenu
{
    position: absolute;
    right: 0;
    padding: 10px;
    top: 110px;
    opacity: 0.1;
    visibility: hidden;
}

#debugMenu .btnColor
{
    width: 160px;
    margin: 10px;
}

/* PORTRAIT */
@media (orientation: portrait) {
    /*#contact-us-button
    {
        top: 110px;
        right: 25px;
    }*/

    #menu-button
    {
        top: 25px;
        right: 25px;
    }

    .point .point-circle
    {
        width: 8vh;
        height: 8vh;
        top: -4vh;
        left: -4vh;
    }

    .point-circle-inner
    {
        width: 4vh;
        height: 4vh;
        top: -2vh;
        left: -2vh;
        font-size: 2.75vh;
    }

    .point .point-text
    {
        transform: translateX(4.5vmin);
    }

    .menu-btn
    {
        margin: 30px auto;
        padding: 17px 0;
        font-size: 1.5em;
        max-width: 65%;
        box-shadow: 0px 5px 4px #00000030;
    }

    .product-description-container
    {
        width: 100%;
        height: 45%;
        top: 45%;
        padding: 0 7%;
        /* background-color: #00000025; */
    }

    .product-description
    {
        font-size: 1.2em;
    }

    #nameText
    {
        font-size: 2em;
        /* margin-bottom: 20px; */
        padding: 10px 0;
        height: 9%;
    }

    .language-buttom
    {
        visibility: hidden;
    }

    .language-buttom.selected
    {
        visibility: initial;
    }
}

/*@media (min-width: 576px) {
    .class {
    }
}*/

/* @media (min-width: 481px) and (max-width: 767px) { /* Mobile */
@media only screen /*and (hover: none)*/ and (pointer: coarse) {
    #popup-message {
        width: 65vw;
    }

    #language-menu
    {
        left: 20px;
        top: 20px;
    }

    .logo {
        top: 20px;
    }

    .rooms-selector-room {
        height: 28vh;
    }

    .centered-lower-text-container
    {
        /* bottom: 16%; */
    }

    .centered-lower-text {
        font-size: 1.4rem;
    }

    .centered-lower-text-line
    {
        width: 160px;
        bottom: -21px;
    }

    #room-name-subtitle {
        font-size: 0.85rem;
        line-height: 1.0rem;
    }

    #nameText {
        margin-bottom: 2px;
        margin-left: -10%;
        margin-right: -10%;
        padding-top: 16vh;
    }

    #descriptionText
    {
        margin-bottom: 30px;
    }

    #menu-buttons
    {
        top: 33vh;
        bottom: 75px;
    }

    #menu-socials
    {
        margin-top: 18vh;
    }

    .social-button
    {
        width: 45px;
        height: 45px;
    }

    #menu-close-button
    {
        bottom: 40px;
    }

    #descriptionClose
    {
        bottom: 7%;
    }

    #descriptionClose-text
    {
        font-size: 0.9em;
    }

    .btnColor
    {
        width: 30px;
        height: 30px;
    }

    #descriptionPrevious
    {
        left: -40px;
        top: 32%;
    }

    #descriptionNext
    {
        right: -40px;
        top: 32%;
    }

    .product-description
    {
        font-size: 1em;
    }

    .product-description-wrapper {
        top: 3%;
    }

    .btnDark {
        width: 54px;
        height: 54px;
    }

    #contact-us-button
    {
        bottom: 17px;
        right: 17px;
    }
    #back-to-machines
    {
        bottom: 17px;
        left: 17px;
    }
    #menu-button
    {
        top: 17px;
        right: 17px;
    }

    .point .point-circle
    {
        width: 9vh;
        height: 9vh;
        top: -4.5vh;
        left: -4.5vh;
    }

    .point-circle-inner
    {
        width: 4.5vh;
        height: 4.5vh;
        top: -2.5vh;
        left: -2.5vh;
        font-size: 3vh;
    }

    #fullscreen-gallery-container .swiper-wrapper {
        height: 73vh !important;
        margin-top: 11vh !important;
    }

    #fullscreen-gallery-close-button {
        top: 20px;
        right: 90px;
    }

    .description-link-btn {
        max-width: 90%;
        min-width: 90%;
    }
}
