@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;700;900&family=Open+Sans:wght@300;400;600;700&display=swap');

body {
    margin: 0;
    color: #6a6f8c;
    background: #313131;
    font: 600 16px/18px 'Lato', sans-serif;
}

*,
:after,
:before {
    box-sizing: border-box
}

.clearfix:after,
.clearfix:before {
    content: '';
    display: table
}

.clearfix:after {
    clear: both;
    display: block
}

a {
    color: inherit;
    text-decoration: none
}

.login-logo {
    position: relative;
    /* top: 32%;  */
    /* left: 50%; */
    /* transform: translate(-50%, 0%); */
    margin: 0 auto;
    content: url('../static/images/ui/ferraro_entry_icon_full.png');
    min-height: 60px;
    max-height: 122px;
    margin-bottom: 40px;
}

.login-logo-bigger {
    min-height: 150px;
    max-height: 170px;
    margin-bottom: 7px;
}

@media only screen and (max-height: 500px) {
    .login-logo {
        margin-bottom: 20px;
    }

    .login-logo-bigger {
        min-height: 100px;
        max-height: 110px;
        margin-bottom: 1px;
    }

    #loginBtn {
        margin-top: 20px !important;
    }
  }
  

.login-wrap {
    width: 70%;
    margin: auto;
    max-width: 425px;
    /* min-height:200px; */
    position: absolute;
    top: 51%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* background:url(https://raw.githubusercontent.com/khadkamhn/day-01-login-form/master/img/bg.jpg) no-repeat center; */
    /* background-color: #08254470; */
    /* box-shadow:0 12px 15px 0 rgba(0,0,0,.24),0 17px 50px 0 rgba(0,0,0,.19); */
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    justify-items: center;
    height: 55vh;
    max-height: 398px;
    transition: visibility 0.3s, opacity 0.3s linear;
}

#experience-div {
    top: 55%;
}

.login-html {
    width: 100%;
    /* height: 100%; */
    position: relative;
    /* padding: 50px 70px 50px 70px; */
    /* background: #00000070; */
    border-radius: 5px;
}

.login-html .sign-in-htm,
.login-html .sign-up-htm {
    top: 55px;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    transition: all .4s linear;
}

.login-html .sign-in,
.login-html .sign-up,
.login-form .group .check {
    display: none;
}

.login-html .tab,
.login-form .group .label,
.login-form .group .button {
    text-transform: uppercase;
    font-size: 20px;
}

.login-html .tab {
    font-size: 22px;
    margin-right: 15px;
    padding-bottom: 5px;
    margin: 0 15px 10px 0;
    display: inline-block;
    border-bottom: 2px solid transparent;
}

.login-html .sign-in:checked+.tab,
.login-html .sign-up:checked+.tab {
    color: #fff;
    border-color: var(--ferraro-color-main);
}

.login-form {
    /* min-height:345px; */
    position: relative;
    perspective: 1000px;
    transform-style: preserve-3d;
}

.login-form .group {
    /* margin: 0 80px 25px 80px; */
}

.login-input {
    margin: 0 auto 20px auto;
}

.login-form .group .label,
.login-form .group .login-input {
    width: 65%;
    color: #111111;
    display: block;
}

.login-form .group .button {
    /* width: 100%; */
    color: white;
    display: block;
}

.login-form .group .login-input,
.login-form .group .button {
    border: none;
    padding: 15px 20px;
    /* border-radius:25px; */
    /* background:rgba(255,255,255,.1); */
    outline: none;
}

#enterExperienceBtn {
    margin: 35px auto;
    width: max-content;
}

#loginBtn {
    margin-top: 40px;
    margin-left: auto;
    margin-bottom: 20px;
    margin-right: auto;
    /* width: 65%; */
    padding-left: 40px;
    padding-right: 40px;
}

.login-form .group .login-input {
    padding: 10px 10px 10px 20px;
    font-size: 16px;
    font-weight: 600;
    color: #505050;
    border: 2px solid #00000000;
    box-shadow: 0px 0px 10px #00000010;
    transition: border-color 0.3s;
}

.login-form .group .login-input:focus {
    border-color: var(--ferraro-color-main);
    /* padding: 14px; */
}

.login-form .group input[data-type="password"] {
    text-security: circle;
    -webkit-text-security: circle;
}

.login-form .group .label {
    color: #aaa;
    font-size: 14px;
    margin-bottom: 15px;
}

.login-form .group .button {
    background: var(--ferraro-color-main);
    cursor: pointer;
    font-weight: 600;
    text-align: center;
    user-select: none;
    transition: background 0.3s;
}

.login-form .group .button:hover {
    background: var(--ferraro-color-main-dark);
}

.login-form .group label .icon {
    width: 15px;
    height: 15px;
    border-radius: 2px;
    position: relative;
    display: inline-block;
    background: rgba(255, 255, 255, .1);
}

.login-form .group label .icon:before,
.login-form .group label .icon:after {
    content: '';
    width: 10px;
    height: 2px;
    background: #fff;
    position: absolute;
    transition: all .2s ease-in-out 0s;
}

.login-form .group label .icon:before {
    left: 3px;
    width: 5px;
    bottom: 6px;
    transform: scale(0) rotate(0);
}

.login-form .group label .icon:after {
    top: 6px;
    right: 0;
    /* transform:scale(0) rotate(0); */
}

.login-form .group .check:checked+label {
    color: #fff;
}

.login-form .group .check:checked+label .icon {
    background: var(--ferraro-color-main);
}

.login-form .group .check:checked+label .icon:before {
    transform: scale(1) rotate(45deg);
}

.login-form .group .check:checked+label .icon:after {
    transform: scale(1) rotate(-45deg);
}

.login-html .sign-in:checked+.tab+.sign-up+.tab+.login-form .sign-in-htm {
    transform: rotate(0);
}

.login-html .sign-up:checked+.tab+.login-form .sign-up-htm {
    transform: rotate(0);
}

.hr {
    height: 2px;
    margin: 30px 0 30px 0;
    background: rgba(255, 255, 255, .2);
}

.foot-lnk {
    text-align: center;
}

.fade-out {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.3s, opacity 0.3s linear;
}

/* Shake Animation */

.shake {
    animation: shake 0.52s cubic-bezier(.36, .07, .19, .97) both;
    /* transform: translateX(0); */
    backface-visibility: hidden;
    /* perspective: 1000px; */
}

@keyframes shake {

    10%,
    90% {
        transform: translate(-51%, -50%);
    }

    20%,
    80% {
        transform: translate(-48%, -50%);
    }

    30%,
    50%,
    70% {
        transform: translate(-52%, -50%);
    }

    40%,
    60% {
        transform: translate(-50%, -50%);
    }

    100% {
        transform: translate(-50%, -50%);
    }
}

/* SWIPER */
:root {
    --swiper-navigation-color: #ffffff !important;
    --swiper-theme-color: #ffffff !important;
    --swiper-pagination-bullet-size: 20px !important;
    --swiper-navigation-size: 60px !important;
}

/* Mobile */
@media only screen and (pointer: coarse) {
    :root {
        --swiper-navigation-size: 40px !important;
    }
}

#rooms-selector-container .swiper {
    width: 100%;
    height: 100%;
    user-select: none;
}

#rooms-selector-container .swiper-wrapper {
    height: 60vh !important;
    margin-top: 18vh !important;
    margin-bottom: 20vh !important;
}

#rooms-selector-container .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #00000000;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}

#rooms-selector-container .swiper-slide img {
    display: block;
    max-width: 100%;
    height: 100%;
    transform: scale(1.0);
    opacity: 0.7;
    object-fit: contain;
    user-select: none;
    cursor: pointer;
    filter: grayscale(1) contrast(90%);
    -webkit-filter: grayscale(1) contrast(90%);
    transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    /* background-color: #00000067; */
    /* border: 2px solid rgba(51, 0, 0, 0.212); */
}

#rooms-selector-container .swiper-slide-active img {
    max-width: 105%;
    height: 100%;
    transform: scale(1.3);
    z-index: 1;
    opacity: 0;
    filter: none;
    -webkit-filter: none;
}

.room-image-glow {
    position: absolute;
    opacity: 0 !important;
}

#rooms-selector-container .swiper-slide-active .room-image-glow {
    opacity: 1 !important;
}

.swiper-pagination {
    bottom: 4% !important;
}

.swiper-pagination-bullet {
    background-color: #ffffff70 !important;
    border: 3px solid #ffffff !important;
    transition: background-color 0.15s, background 0.15s, opacity 0.15s;
}

.swiper-button-prev {
    right: 70% !important;
    left: auto !important;
    content: url('../static/images/ui/arrow_icon_left.png');
}

.swiper-button-next {
    left: 70% !important;
    right: auto !important;
    content: url('../static/images/ui/arrow_icon_right.png');
}

.swiper-button-prev, .swiper-button-next {
    top: initial !important;
    bottom: 8% !important;
    background-color: #ffffff66 !important;
    border: 3px solid #ffffff !important;
    border-radius: 100%;
    width: var(--swiper-navigation-size) !important;
    user-select: none;
    transition: background-color 0.15s, background 0.15s, opacity 0.15s;
}
